import React, { useState, useEffect, ChangeEvent } from 'react';
import Fuse from 'fuse.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import '../css/index.scss';

let API_URL = import.meta.env.PUBLIC_API_URL || 'http://localhost:3000';

// Define the structure of the rate data
interface Rate {
    id: string;
    item: string;
    enemy: string;
    requirements: string;
    dropRateDecimal: string;
    dropRateFraction: string;
    newDropRateDecimal: string;
    newDropRateFraction: string;
    tag: string;
}

export default function RatesTable() {
    const [rates, setRates] = useState<Rate[]>([]);
    const [filteredRates, setFilteredRates] = useState<Rate[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [sortConfig, setSortConfig] = useState<{ key: RateSortKey; direction: 'asc' | 'desc' } | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [itemsPerPage] = useState<number>(10);
    const [loading, setLoading] = useState<boolean>(true);
    const [pinnedRates, setPinnedRates] = useState<Rate[]>([]);
    const [stats, setStats] = useState<any>([]);
    const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);

    type RateSortKey = 'item' | 'enemy' | 'requirements' | 'tag' | 'dropRateFraction'; 

    const [displayTotalBosses, setDisplayTotalBosses] = useState(0);
    const [displayTotalDrops, setDisplayTotalDrops] = useState(0);
    const [displayAvgRate, setDisplayAvgRate] = useState(0);

    useEffect(() => {
        fetch(`${API_URL}/droprates/stats`)
            .then((response) => response.json())
            .then((data) => {
                setStats(data);
            })
            .catch((error) => {
                console.error('Error fetching stats:', error);
        });

        fetch(`${API_URL}/droprates/all`)
            .then((response) => response.json())
            .then((data) => {
                const combinedRates: Rate[] = [];
                for (const key in data) {
                    if (Array.isArray(data[key])) {
                        combinedRates.push(...data[key].map((item: any, index: number) => ({
                            ...item,
                            id: `${key}-${index}`,
                        })));
                    }
                }
                
                setRates(combinedRates);
                setFilteredRates(combinedRates);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching rates:', error);
                setLoading(false);
            });

        const savedPinnedRates = localStorage.getItem('pinnedRates');
        if (savedPinnedRates) {
            setPinnedRates(JSON.parse(savedPinnedRates));
        }
    }, []);

    useEffect(() => {
        if (!loading) {
            const bossTimer = setInterval(() => {
                setDisplayTotalBosses(prev => {
                    if (prev < stats.totalBosses) return prev + 4;
                    clearInterval(bossTimer);
                    return prev;
                });
            }, 20);

            const dropTimer = setInterval(() => {
                setDisplayTotalDrops(prev => {
                    if (prev < stats.totalDrops) return prev + 50;
                    clearInterval(dropTimer);
                    return prev;
                });
            }, 20);

            const avgRateTimer = setInterval(() => {
                setDisplayAvgRate(prev => {
                    const targetRate = parseFloat(stats.averageDropRate);
                    if (prev < targetRate) {
                        const nextValue = (prev + 0.004).toFixed(4);
                        return parseFloat(nextValue);
                    }
                    clearInterval(avgRateTimer);
                    return targetRate;
                });
            }, 20);
            

            return () => {
                clearInterval(bossTimer);
                clearInterval(dropTimer);
                clearInterval(avgRateTimer);
            };
        }
    }, [loading, stats.totalBosses, stats.totalDrops, stats.averageDropRate]);

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1);
        const fuse = new Fuse(rates, {
            keys: ['item', 'enemy', 'requirements', 'tag'],
            threshold: 0.3,
        });
        const results = event.target.value ? fuse.search(event.target.value).map(result => result.item) : rates;
        setFilteredRates(results);
    };

    const handleSort = (key: RateSortKey) => {
        setSortConfig(prevConfig => {
            if (prevConfig && prevConfig.key === key) {
                return { key, direction: prevConfig.direction === 'asc' ? 'desc' : 'asc' };
            }
            return { key, direction: 'asc' };
        });
    };

    const sortedCurrentRates = React.useMemo(() => {
        let sortableRates = [...filteredRates.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)];
        if (sortConfig !== null) {
            sortableRates.sort((a, b) => {
                const aValue = a[sortConfig.key]; 
                const bValue = b[sortConfig.key];
                if (aValue < bValue) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableRates;
    }, [filteredRates, sortConfig, currentPage, itemsPerPage]);

    const totalPages = Math.ceil(filteredRates.length / itemsPerPage);

    const handlePageChange = (direction: 'prev' | 'next') => {
        setCurrentPage(prevPage => {
            if (direction === 'prev' && prevPage > 1) {
                return prevPage - 1;
            } else if (direction === 'next' && prevPage < totalPages) {
                return prevPage + 1;
            }
            return prevPage;
        });
    };

    const handlePin = (rate: Rate) => {
        if (!pinnedRates.some(pinnedRate => pinnedRate.id === rate.id)) {
            const updatedPinnedRates = [...pinnedRates, rate];
            setPinnedRates(updatedPinnedRates);
            localStorage.setItem('pinnedRates', JSON.stringify(updatedPinnedRates));
        }
    };

    const handleUnpin = (rate: Rate) => {
        const updatedPinnedRates = pinnedRates.filter(pinnedRate => pinnedRate.id !== rate.id);
        setPinnedRates(updatedPinnedRates);
        localStorage.setItem('pinnedRates', JSON.stringify(updatedPinnedRates));
    };

    return (
        <div className="container mx-auto lg:pr-24 lg:pl-24 sm:pl-12 sm:pr-2 md:pl-12 md:pr-2" style={{ touchAction: 'none' }}>
            <h1 className="text-4xl mb-4 title text-white justify-center align-center text-center">Statistics</h1>
            <section className="py-3 hidden md:block">
                <div className="container px-4 mx-auto">
                    <div className="mb-6">
                    <div className="flex flex-wrap items-center -mx-3 -mb-6">
                        <div className="w-full sm:w-1/2 xl:w-1/4 px-3 mb-6">
                        <div className="max-w-xs md:max-w-none mx-auto p-6 bg-blue-500 rounded-xl">
                            <div className="flex flex-wrap items-center -m-2">
                            <div className="w-auto p-2">
                                <div className="flex flex-shrink-0 w-12 h-12 items-center justify-center bg-blue-500 bg-opacity-20 text-blue-500 rounded-xl">
                                 <img src='https://i.imgur.com/qCstxjS.png'/>
                                </div>
                            </div>
                            <div className="w-auto p-2">
                                <h5 className="text-xs text-gray-200 leading-5 font-semibold title"># Of Enemies</h5>
                                <div className="flex flex-wrap items-center -m-1">
                                <div className="w-auto p-1">
                                    <span className="text-2xl leading-none text-gray-100 font-semibold body">
                                        {loading ? 'Loading...' : displayTotalBosses}
                                    </span>
                                </div>
                            
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="w-full sm:w-1/2 xl:w-1/4 px-3 mb-6">
                        <div className="max-w-xs md:max-w-none mx-auto p-6 bg-blue-500 rounded-xl">
                            <div className="flex flex-wrap items-center -m-2">
                            <div className="w-auto p-2">
                                <div className="flex flex-shrink-0 w-12 h-12 items-center justify-center bg-blue-500 bg-opacity-20 text-blue-500 rounded-xl">
                                 <img src='/sprites/Insignia Of Valor.png'/>
                                </div>
                            </div>
                            <div className="w-auto p-2">
                                <h5 className="text-xs text-gray-200 leading-5 font-semibold title"># Of Drops</h5>
                                <div className="flex flex-wrap items-center -m-1">
                                <span className="text-2xl leading-none text-gray-100 font-semibold body">
                                        {loading ? 'Loading...' : displayTotalDrops}
                                </span>
                            
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="w-full sm:w-1/2 xl:w-1/4 px-3 mb-6">
                        <div className="max-w-xs md:max-w-none mx-auto p-6 bg-blue-500 rounded-xl">
                            <div className="flex flex-wrap items-center -m-2">
                            <div className="w-auto p-2">
                                <div className="flex flex-shrink-0 w-12 h-12 items-center justify-center bg-blue-500 bg-opacity-20 text-blue-500 rounded-xl">
                                 <img src='https://vwiki.valorserver.com/api/item/picture/starmind%20gauntlet'/>
                                </div>
                            </div>
                            <div className="w-auto p-2">
                                <h5 className="text-xs text-gray-200 leading-5 font-semibold title">Avg Drop Rate %</h5>
                                <div className="flex flex-wrap items-center -m-1">
                                <div className="w-auto p-1">
                                    <span className="text-2xl leading-none text-gray-100 font-semibold body">
                                            {loading ? 'Loading...' : displayAvgRate}
                                    </span>
                                </div>
                            
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="w-full sm:w-1/2 xl:w-1/4 px-3 mb-6">
                        <div className="max-w-xs md:max-w-none mx-auto p-6 bg-blue-500 rounded-xl" style={{ display: 'flex', flexDirection: 'column' }}>
                            <div className="flex items-center -m-2 flex-nowrap">
                                <div className="flex-shrink-0 p-2" style={{ flex: '0 0 auto' }}>
                                    <div className="flex items-center justify-center" style={{ width: '48px', height: '48px'  }}>
                                        <img src='/sprites/Potion Of Attack.png' alt="Most Common Item" style={{ maxWidth: '100%', maxHeight: '100%' }}/>
                                    </div>
                                </div>
                                <div className="p-2" style={{ flex: '1 0 auto', minWidth: '150px' }}>
                                    <h5 className="text-xs text-gray-200 leading-5 pb-1 font-semibold title">Most Common Item</h5>
                                    <div className="flex items-center -m-1">
                                        <span className="text-xl leading-none text-gray-100 font-semibold body">
                                            {loading ? 'Loading...' : stats.mostCommonItem}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        
                    </div>
                    </div>
                </div>
                </section>
            <input
                type="text"
                value={searchTerm}
                onChange={handleSearch}
                placeholder="Search rates"
                className="body w-full p-2 mb-4 border bg-gray-800 text-white border-gray-300 rounded"
            />
            
            {loading ? (
                <div className="text-center">Loading...</div>
            ) : (
                <> 
                    {pinnedRates.length > 0 && (
                        <div className="mb-4">
                            <h2 className="text-2xl text-gray-200 font-bold mb-2 title">Pinned Rates</h2>
                            <div className="overflow-x-auto">
                                <table className="min-w-full border border-gray-200">
                                    <thead>
                                        <tr>
                                            <th className="px-4 py-2 border font-bold title">Item</th>
                                            <th className="px-4 py-2 border font-bold title">Enemy</th>
                                            <th className="px-4 py-2 border font-bold title">Requirements</th>
                                            <th className="px-4 py-2 border font-bold title">Type</th>
                                            <th className="px-4 py-2 border font-bold title">Drop Rate</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {pinnedRates.map((rate) => (
                                            <tr key={rate.id} onDoubleClick={() => handleUnpin(rate)} className="cursor-pointer hover:text-white hover:bg-blue-400">
                                                <td className="px-4 py-2 border">
                                                <img
                                                    className="w-8 h-8 inline"
                                                    src={`https://vwiki.valorserver.com/api/item/picture/${rate.item}`}
                                                    alt={rate.item}
                                                    onError={(e) => {
                                                    const target = e.target as HTMLImageElement;
                                                    target.style.display = 'none';
                                                    }}
                                                />
                                                {rate.item}
                                                </td>                                                     
                                                <td className="px-4 py-2 border body">{rate.enemy}</td>
                                                <td className="px-4 py-2 border body">{rate.requirements}</td>
                                                <td className="px-4 py-2 border body">{rate.tag}</td>
                                                <td className="px-4 py-2 border body">{rate.dropRateFraction}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}
                    <h2 className="text-2xl text-gray-200 font-bold mb-2 title">Droprates</h2>
                    <div className="overflow-x-auto">
                        <table className="min-w-full border border-gray-200 fixed-table">
                        <thead>
                            <tr>
                                <th className="px-4 py-2 border font-bold title cursor-pointer" onClick={() => handleSort('item')}>
                                    Item {sortConfig?.key === 'item' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : ''}
                                </th>
                                <th className="px-4 py-2 border font-bold title cursor-pointer" onClick={() => handleSort('enemy')}>
                                    Enemy {sortConfig?.key === 'enemy' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : ''}
                                </th>
                                <th className="px-4 py-2 border font-bold title cursor-pointer" onClick={() => handleSort('requirements')}>
                                    Requirements {sortConfig?.key === 'requirements' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : ''}
                                    <FontAwesomeIcon
                                        icon={faInfoCircle}
                                        className="ml-2 text-gray-400 cursor-pointer"
                                        onMouseEnter={() => setTooltipVisible(true)}
                                        onMouseLeave={() => setTooltipVisible(false)}
                                        onClick={() => setTooltipVisible(!tooltipVisible)}
                                    />
                                    {tooltipVisible && (
                                        <div className="tooltip absolute bg-gray-800 text-white text-xs rounded p-2 mt-1">
                                            Thresholds determine the conditions that need to be met for the drop rates to apply.
                                            <p>Scaling Threshold: Starts at either 2.5% or 5% depending on LG/AR and scales down with group size.</p>
                                            <p>Most Damagers: The top X # of players who dealt the most damage are entered into the loot roll.</p>
                                        </div>
                                    )}
                                </th>
                                <th className="px-4 py-2 border font-bold title cursor-pointer" onClick={() => handleSort('tag')}>
                                    Type {sortConfig?.key === 'tag' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : ''}
                                </th>
                                <th className="px-4 py-2 border font-bold title cursor-pointer" onClick={() => handleSort('dropRateFraction')}>
                                    Drop Rate (Fraction) {sortConfig?.key === 'dropRateFraction' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : ''}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                                {sortedCurrentRates.map((rate) => (
                                    <tr key={rate.id} onDoubleClick={() => handlePin(rate)} className="cursor-pointer hover:bg-blue-400">
                                        <td className="px-4 py-2 border">
                                        <img
                                            className="w-8 h-8 inline"
                                            src={`https://vwiki.valorserver.com/api/item/picture/${rate.item}`}
                                            alt={rate.item}
                                            onError={(e) => {
                                            const target = e.target as HTMLImageElement;
                                            target.style.display = 'none';
                                            }}
                                        />
                                        {rate.item}
                                        </td>        
                                        <td className="px-4 py-2 border body">{rate.enemy}</td>
                                        <td className="px-4 py-2 border body">{rate.requirements}</td>
                                        <td className="px-4 py-2 border body">{rate.tag}</td>
                                        <td className="px-4 py-2 border body">{rate.dropRateFraction}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="flex justify-between items-center mt-4 pb-4">
                        <button 
                            onClick={() => handlePageChange('prev')}
                            disabled={currentPage === 1}
                            className="px-4 py-2 bg-blue-600 rounded disabled:bg-grey-100 disabled:opacity-50"
                        >
                            Previous
                        </button>
                        <span>Page {currentPage} of {totalPages}</span>
                        <button
                            onClick={() => handlePageChange('next')}
                            disabled={currentPage === totalPages}
                            className="px-4 py-2 bg-blue-600 rounded disabled:bg-grey-100 disabled:opacity-50"
                        >
                            Next
                        </button>
                    </div>
                    <div className="mt-4 md:hidden">
                        <button
                            onClick={() => handlePageChange('prev')}
                            className="block w-full p-2 mb-2 bg-blue-500 text-white rounded"
                        >
                            Previous Page
                        </button>
                        <button
                            onClick={() => handlePageChange('next')}
                            className="block w-full p-2 bg-blue-500 text-white rounded"
                        >
                            Next Page
                        </button>
                    </div>
                </>
            )}
        </div>
    );
}
